import React, { useEffect } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { usePrismicPreview } from 'gatsby-source-prismic';

const Preview = ({ location }) => {
  const { allPrismicPage, site } = useStaticQuery(query);

  const pageUIDs = allPrismicPage.nodes.map((node) => node.uid);

  const {
    siteMetadata: { prismicRepo },
  } = site;

  const pathResolver = () => (doc) => {
    if (doc.type === 'settings') return '/';

    const previewedUID = doc.uid;

    if (previewedUID === 'home') return '/';

    if (pageUIDs.includes(previewedUID)) {
      return `/${previewedUID}`;
    }

    return '/unpublishedPreview';
  };

  const { previewData, path } = usePrismicPreview({
    repositoryName: prismicRepo,
    pathResolver,
  });

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData;
      navigate(path);
    }
  }, [previewData, path]);

  return <div>Loading preview...</div>;
};

export default Preview;

const query = graphql`
  query previewQuery {
    allPrismicPage {
      nodes {
        id
        uid
        prismicId
        data {
          meta_title {
            text
          }
          meta_description {
            text
          }
          ogImage: open_graph_image {
            url
          }
          schema {
            text
          }
          body {
            ...CtaPageFragment
            ...ContactPageFragment
            ...FeatureColumnsPageFragment
            ...HeaderSlicePageFragment
            ...ImageGridPageFragment
            ...JobsBoardPageFragment
            ...MetricsCompactPageFragment
            ...OurOfficesPageFragment
            ...TestimonialsPageFragment
            ...TwoColsTextImagePageFragment
            ...UspGridPageFragment
            ...WysiwygPageFragment
          }
        }
      }
    }
    allPrismicSettings {
      nodes {
        data {
          header_navigation_link_1_link {
            url
          }
          header_navigation_link_1_text {
            text
          }
          header_navigation_link_2_link {
            url
          }
          header_navigation_link_2_text {
            text
          }
          header_navigation_link_3_link {
            url
          }
          header_navigation_link_3_text {
            text
          }
          header_navigation_link_4_link {
            url
          }
          header_navigation_link_4_text {
            text
          }
          footer_navigation_link_1_link {
            url
          }
          footer_navigation_link_1_text {
            text
          }
          footer_navigation_link_2_link {
            url
          }
          footer_navigation_link_2_text {
            text
          }
          footer_navigation_link_3_link {
            url
          }
          footer_navigation_link_3_text {
            text
          }
          footer_navigation_link_4_link {
            url
          }
          footer_navigation_link_4_text {
            text
          }
          footer_navigation_link_5_link {
            url
          }
          footer_navigation_link_5_text {
            text
          }
          footer_navigation_link_6_link {
            url
          }
          footer_navigation_link_6_text {
            text
          }
          footer_navigation_link_7_link {
            url
          }
          footer_navigation_link_7_text {
            text
          }
          footer_navigation_link_8_link {
            url
          }
          footer_navigation_link_8_text {
            text
          }
          contact_email {
            text
            html
          }
          copyright_disclaimer {
            html
            text
          }
          linkedin {
            text
            html
          }
          office_location_address_1 {
            html
            text
          }
          office_location_address_2 {
            html
            text
          }
          home_splash_metrics_title {
            html
            text
          }
          home_splash_metrics_subtitle {
            html
            text
          }
          home_splash_metrics_cta_text {
            html
            text
          }
          home_splash_metrics_cta_slug {
            url
          }
          home_splash_metrics_stat_title_1 {
            html
            text
          }
          home_splash_metrics_stat_subtitle_1 {
            html
            text
          }
          home_splash_metrics_stat_icon_1 {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
          home_splash_metrics_stat_title_2 {
            html
            text
          }
          home_splash_metrics_stat_subtitle_2 {
            html
            text
          }
          home_splash_metrics_stat_icon_2 {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
          home_splash_metrics_stat_title_3 {
            html
            text
          }
          home_splash_metrics_stat_subtitle_3 {
            html
            text
          }
          home_splash_metrics_stat_icon_3 {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
          home_splash_metrics_stat_title_4 {
            html
            text
          }
          home_splash_metrics_stat_subtitle_4 {
            html
            text
          }
          home_splash_metrics_stat_icon_4 {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
          home_two_cols_cta_image_grid_group {
            home_two_cols_cta_grid_title {
              html
              text
            }
            home_two_cols_cta_grid_subtitle {
              html
              text
            }
            home_two_cols_cta_grid_cta_link_text {
              html
              text
            }
            home_two_cols_cta_grid_cta_link_slug {
              url
            }
            home_two_cols_cta_grid_link_colour
            home_two_cols_grid_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        prismicRepo
      }
    }
  }
`;
